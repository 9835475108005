import React, { useState } from 'react';
import { makeStyles, shorthands, Button } from '@fluentui/react-components';
import { Copy16Regular, Checkmark16Regular } from '@fluentui/react-icons';
import { OutputCodeBlockProps } from './OutputCodeBlock';

const useClasses = makeStyles({
    container: {
        position: 'relative', // Added position relative for absolute positioning of the copy button
        maxWidth: '800px', //could change to 100% or 80%. Didn't see this change anything
        maxHeight: '500px',
        marginTop: '10px',
        marginBottom: '10px',
    },

    preContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        maxHeight: '400px',
        ...shorthands.margin('0px'),
        ...shorthands.padding('10px'),
        ...shorthands.borderRadius('5px'),
        ...shorthands.overflow('auto'),
        backgroundColor: '#f5f5f5',
        color: '#004068',
        cursor: 'text',
        overflowY: 'auto',
        overflowX: 'auto',
        '&:hover ': {
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
                ...shorthands.borderRadius('12px'),
                backgroundColor: '#bbb',
            },
            cursor: 'auto'
        }

    },

    codeLanguageArea: {
        color: '#fff',
        backgroundColor: '#004068',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '2px',
        paddingBottom: '2px',
    },

    copyButton: {
        position: 'absolute',
        // top: '20px',
        // top: '4px',

        right: '12px',
        zIndex: 1,
        cursor: 'pointer',

        '&:hover': {},
    },

    successCheckMark: {
        color: 'green',
        fontWeight: 'bold',
    },
});

interface OutputPreBlockProps {
    children?: React.ReactNode;
}

export const OutputPreBlock: React.FC<OutputPreBlockProps> = (props) => {
    const { children } = props;
    const classes = useClasses();

    const [copySuccess, setCopySuccess] = useState(false);
    const [isHovered, setHovered] = useState(false);

    const codeElement = children as React.ReactElement<OutputCodeBlockProps>;

    const languageClassName = codeElement.props.className ?? '';
    const languageMatch = /language-(\w+)/.exec(languageClassName);
    const language = languageMatch ? languageMatch[1] : '';

    const handleCopyToClipboard = () => {
        navigator.clipboard
            .writeText(codeElement.props.children?.toString() ?? '')
            .then(() => {
                setCopySuccess(true);
                setTimeout(() => {
                    setCopySuccess(false);
                }, 2000);
            })
            .catch((err) => {
                console.error('Failed to copy to clipboard', err);
                setCopySuccess(false);
            });
    };

    return (
        <div
            className={classes.container}
            onMouseOver={() => {
                setHovered(true);
            }}
            onMouseOut={() => {
                setHovered(false);
            }}
        >
            {language && <div className={classes.codeLanguageArea}>{language}</div>}
            <pre className={classes.preContainer}>{children}</pre>
            {isHovered && (
                <div className={classes.copyButton} style={language ? { top: '27px' } : { top: '4px' }}>
                    <Button
                        icon={
                            copySuccess ? (
                                <Checkmark16Regular className={classes.successCheckMark} />
                            ) : (
                                <Copy16Regular />
                            )
                        }
                        // appearance="transparent"
                        onClick={handleCopyToClipboard}
                        onMouseOver={() => {
                            setHovered(true);
                        }}
                        onMouseOut={() => {
                            setHovered(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
