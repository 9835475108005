import React from 'react';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { customTokens } from '../../../../styles';

// const useClasses = makeStyles({
//     tableContainer: {
//         maxWidth: '100%',
//         marginTop: '10px',
//         marginBottom: '10px',
//         overflowX: 'auto',
//         boxSizing: 'border-box',

//         '& table': {
//             width: '100%', // Adjusted from 100% to allow horizontal overflow
//             tableLayout: 'fixed',
//             borderSpacing: '0',
//             ...shorthands.borderTop('1px', 'solid', '#bbb'),
//             ...shorthands.borderLeft('1px', 'solid', '#bbb'),
//             wordBreak: 'normal',
//         },
//         '& th, & td': {
//             ...shorthands.padding('5px'),
//             verticalAlign: 'top',
//             ...shorthands.borderBottom('1px', 'solid', '#bbb'),
//             ...shorthands.borderRight('1px', 'solid', '#bbb'),
//             // width: 'clamp(20px, 10%, 200px)', // Adjust as needed
//             // width: 'max(200px, min(520px, calc(100vw - 680px)))',
//             // width: 'clamp(100px, 200px, 300px)',
//             // width: 'clamp(3rem, 5vw, 6vw)',
//             // width: 'clamp(100px, calc(100vw - 680px), 520px)',
//         },
//         '& th': {
//             backgroundColor: '#efefef',
//             fontWeight: 500,
//             textAlign: 'left',
//             whiteSpace: 'nowrap',
//         },

//         '&::-webkit-scrollbar': {
//             width: '8px',
//             height: '8px',
//         },
//         '&::-webkit-scrollbar-thumb': {
//             ...shorthands.borderRadius('12px'),
//             backgroundColor: '#bbb',
//         },
//     },
// });

const useClasses = makeStyles({
    tableContainer: {
        maxWidth: '100%', // could change to 80% if you don't want tables to take up the full width
        marginTop: '10px',
        marginBottom: '10px',
        overflowX: 'auto',
        boxSizing: 'border-box',

        '& table': {
            // width: '100%',
            tableLayout: 'fixed',
            borderSpacing: '0',
            ...shorthands.borderTop('1px', 'solid', customTokens.colorNeutralBackground6),
            ...shorthands.borderLeft('1px', 'solid', customTokens.colorNeutralBackground6),
            // ...shorthands.overflow('hidden'),
            // borderCollapse: 'collapse',
            // ...shorthands.borderBottom('1px solid #ddd'),
            // ...shorthands.borderRadius('5px'),
            wordBreak: 'normal',
        },
        '& th, & td': {
            ...shorthands.padding('5px'),
            verticalAlign: 'top',
            ...shorthands.borderBottom('1px', 'solid', customTokens.colorNeutralBackground6),
            ...shorthands.borderRight('1px', 'solid', customTokens.colorNeutralBackground6),
            boxSizing: 'border-box',
            minWidth: '10vw',
            width: 'auto',
        },
        '& th': {
            backgroundColor: customTokens.colorNeutralBackground2,
            fontWeight: 500,
            textAlign: 'left',
        },
        // '& table': {
        //     ...shorthands.overflow('hidden'),
        //     borderCollapse: 'collapse',
        //     ...shorthands.borderBottom('1px solid #ddd'),
        //     ...shorthands.borderRadius('5px'),
        //     wordBreak: 'normal',
        // },
        // '& th': {
        //     minWidth: '10%', // could set to something static like 200px, but that made small tables huge
        //     maxWidth: '50%',
        //     borderCollapse: 'collapse',
        //     borderSpacing: '0',
        //     boxSizing: 'inherit',
        //     // ...shorthands.padding('10px'),
        //     paddingTop: '5px',
        //     paddingRight: '10px',
        //     paddingBottom: '5px',
        //     paddingLeft: '10px',
        //     display: 'table-cell',
        //     textAlign: 'left',
        //     verticalAlign: 'top',
        //     backgroundColor: '#004068',
        //     fontWeight: 500,
        //     color: '#fff',
        //     whiteSpace: 'nowrap',
        // },
        // '& tbody': {
        //     borderCollapse: 'collapse',
        //     borderSpacing: '0',
        //     boxSizing: 'inherit',
        // },
        // '& tr': {
        //     borderCollapse: 'collapse',
        //     borderSpacing: '0',
        //     boxSizing: 'inherit',
        //     ...shorthands.borderBottom('1px solid #ddd'),
        //     backgroundColor: '#fff',
        // },
        // '& td': {
        //     boxSizing: 'inherit',
        //     ...shorthands.padding('10px'),
        //     display: 'table-cell',
        //     textAlign: 'left',
        //     verticalAlign: 'top',
        //     whiteSpace: 'normal',
        // },
        // '& tr:nth-child(even)': {
        //     backgroundColor: '#fbfbfb',
        // },
        // '& tr:nth-child(odd)': {
        //     backgroundColor: '#f5f5f5',
        // },
        // '& tr:hover, & tr:nth-child(odd):hover, & tr:nth-child(even):hover': {
        //     backgroundColor: '#eeeeee',
        // },

        '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            ...shorthands.borderRadius('12px'),
            backgroundColor: '#bbb',
        },
    },
});

interface OutputTableProps {
    children?: React.ReactNode;
}

export const OutputTable: React.FC<OutputTableProps> = (props) => {
    const classes = useClasses();

    return (
        <div className={classes.tableContainer}>
            <table {...props} />
        </div>
    );
};
