import { shorthands, makeStyles } from '@fluentui/react-components'
import React from 'react'
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';

const useClasses = makeStyles({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 1410px)' : {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '@media (max-width: 920px)' : {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
    },
    container : {
        ...shorthands.padding('15px'), 
        ...shorthands.margin('10px'), 
    },
    categoryContainer : {
        display:'flex',
        flexDirection:'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    category : {
        fontWeight:'600', 
        fontSize:'17px',
        // color:'#8e9094',
    },
    suggestionContainer : {
        display:'flex', 
        flexDirection: 'column',
        ...shorthands.borderRadius('10px'),
        ...shorthands.margin('15px', 0, 0, 0),
    },
    button: {
        // width:'fit-content', 
        ...shorthands.padding('12px'),
        ...shorthands.borderRadius('5px'), 
        fontSize:'15px',
        fontWeight:'500', 
        ...shorthands.margin('3px'),
        textAlign: 'left', 
        backgroundColor: '#E1EBF3',
        ...shorthands.border(0),
        '&:hover' : {
            backgroundColor : '#083d5b',
            cursor:'pointer',
            color:'white',
        }
    } 
})


interface ChatSuggestions{
    category: string;
    suggestions: Record<string, Record<string, string | Record<string, string | string[]>>>;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}


export const ChatWindowBlock: React.FC<ChatSuggestions> = ({ category, suggestions, setValue }) => {
    const classes = useClasses();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

    const handleOnClick = (suggestion: string) => {
        if (suggestion === conversations[selectedId].input){
            setValue("");
        } else {
            setValue(suggestion);
        }
    }

  
    return (
        category === "Small blocks" ? (
        <div className={classes.gridContainer}>
            {Object.keys(suggestions).map((category, index) => (
                <div className={classes.container} key={index}>
                    <span className={classes.category}>{category}</span>
                    <div className={classes.suggestionContainer}>
                    {Object.keys(suggestions[category]).map((smallBlock, index) => (
                        <button
                            className={classes.button}
                            key={index}
                            style={{transition: 'background-color 0.3s ease', borderLeft:'5px solid #083d5b' }}
                            onClick={()=>{handleOnClick(suggestions[category][smallBlock] as string)}}
                            >
                            {smallBlock}    
                        </button>
                    ))}
                    </div>
                </div>
            ))}
        </div>
        ) : (
            <div>

            </div>
        )
    )
}
