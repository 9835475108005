import { shorthands, makeStyles } from '@fluentui/react-components'; 
import promptSuggestions from '../../../prompt-library.json';
import React from 'react';
import { ToolTipBlock } from './ToolTipBlock';

const useClasses = makeStyles({
    toolTipcontainer: {
        backgroundColor: 'White',
        display: 'flex',
        flexDirection: 'column',
        maxHeight:'100%',
        overflowY: 'auto',
        '&:hover' : {
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                ...shorthands.borderRadius('12px'),
                backgroundColor: '#bbb',
            },
        } 
       
        //maxHeight:'450px',
    },
    toolTipRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    largeBlockContainer: {
        ...shorthands.padding('20px', 0, 0, 0),

    },
    suggestionContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 850px)' : {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        // '@media (max-width: 700px)' : {
        //     gridTemplateColumns: 'repeat(1, 1fr)',
        // }
    }
})

interface PopoverContentProps {
    setValue: React.Dispatch<React.SetStateAction<string>>
}

export const PopoverContent: React.FC<PopoverContentProps> = ({ setValue }) => {
    const classes = useClasses();

    return (
        <div className={classes.toolTipcontainer}>
            <div>
                <span style={{ padding: '0px 0px 0px 20px', fontWeight: 'bold', fontSize:'18px'}}>Prompt Suggestions</span>
            </div>
            <div style={{ padding: '20px'}}>
                
            {Object.keys(promptSuggestions).map((parentCategory, parentIndex) => {
                const categorySuggestions = promptSuggestions[parentCategory as keyof typeof promptSuggestions];
                return (
                    <ToolTipBlock
                        key={parentIndex}
                        category={parentCategory}
                        suggestions={categorySuggestions}
                        setValue={setValue}
                    />
                )       
            })}       
            </div>
        </div>
    
    )
}