import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';

export const semanticKernelBrandRampOG: BrandVariants = {
    10: '#060103',
    20: '#261018',
    30: '#431426',
    40: '#591732',
    50: '#701A3E',
    60: '#861F4B',
    70: '#982C57' /*This is it*/,
    80: '#A53E63',
    90: '#B15070',
    100: '#BC627E',
    110: '#C6748B',
    120: '#CF869A',
    130: '#D898A8',
    140: '#E0AAB7',
    150: '#E8BCC6',
    160: '#EFCFD6',
};

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#010305',
    20: '#0E1922',
    30: '#0E283B',
    40: '#193449',
    50: '#284054',
    60: '#374D60',
    70: '#46596B',
    80: '#556777',
    90: '#647483',
    100: '#738290',
    110: '#838F9C',
    120: '#929EA9',
    130: '#A3ACB6',
    140: '#B3BBC3',
    150: '#C3C9D0',
    160: '#D4D9DD',
};

export const customColors = {
    lightBlueGrayBackground1: '#F3F5F6',
    lightBlueGrayBackground2: '#F7F9FA',
    lightBlueGrayBackground3: '#F3F5F7',
    botMessageBorder: '#D0D0D0',
    darkBlue: '#003f68', // I just pulled this from the rgba to hex code value of the border color of the "Add Chats" button
    userMessageBackground1: '#DBE8F3',
    userMessageBackground2: '#DEE8F5',
    userMessageBackground3: '#DDE7F5',
    userMessageBackground4: '#E1EBF3',
    userMessageBackground5: '#d9effa',
    userMessageBackground6: '#e3f2fa',
    summarizeButtonHover: '#083d5b',
    summarizeButton: '#0a5782',
    summarizeButtonDarker: '#004068',
    summarizeButtonHoverLighter: '#1c618c',
    newChatHoverLightBlue: '#92ACBE',
};

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#C3C9D0',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#5A7594',
};

export const customTokens = themeToTokensObject(semanticKernelDarkTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'scroll',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
};

export const useDialogClasses = makeStyles({
    root: {
        height: '515px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
    scopes: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        paddingLeft: '20px',
    },
    error: {
        color: '#d13438',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '10px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
