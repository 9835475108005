import React from 'react'
import { makeStyles, shorthands } from '@fluentui/react-components' 
import { ArrowRight20Regular } from '@fluentui/react-icons';

const useClasses = makeStyles({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 850px)' : {
            gridTemplateColumns: 'repeat(2, 1fr)',
        }
    },
    smallBlockContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F8F8F8',
        ...shorthands.margin('15px'),
        ...shorthands.borderRadius('10px'), 
        ...shorthands.padding('15px')    
    },
    smallBlockCategoryText: {
        color: '#004068', 
        fontWeight: 'bold', 
        fontSize: '16px'
    },
    title: {
        display: 'flex',
        textAlign: 'center'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center'   
    },
    button: {
        backgroundColor:'white',
        cursor: 'auto',
        color: '#004068',
        fontWeight: '500',
        whiteSpace: 'normal',
        textAlign: 'left',  
        ...shorthands.borderRadius('7px'),
        ...shorthands.padding('5px', '10px', '5px', '10px'),
        ...shorthands.margin('10px',0,0,0),
        width:'fit-content',
        fontSize:'15px',
            '&:hover': {
                backgroundColor:'#004068',
                cursor: 'pointer',
                color: '#FFFFFF',
                fontWeight: '700'
            }
    },
    columnContainer: {
        ...shorthands.padding('20px', 0, 0, 0),

    },
    largeBlockContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
    },
    largeBlockCategoryText: {
        ...shorthands.padding(0, 0, '10px', 0),
        fontWeight: 'bold', 
        fontSize: '18px', 
        color: '#030917'
    },
    followUp: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
})

interface ToolTipBlockProps {
    category: string;
    suggestions: Record<string, Record<string, string | Record<string, string | string[]>>>;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}


export const ToolTipBlock: React.FC<ToolTipBlockProps> = ({ category, suggestions, setValue })  => {
    const classes = useClasses();
    const [isHovered, setIsHovered] = React.useState("");

    const handleOnClick = (suggestion: string) => {    
        const contentToCopy: string =  suggestion;
        setValue(contentToCopy)
        document.body.click();
    }


    const handleMouseEnter = (suggestion: string) => {
        setIsHovered(suggestion);
      };
    
      const handleMouseLeave = () => {
        setIsHovered("");
      };

    return (
        category === "Small blocks"? (
            <div className={classes.gridContainer}>
                   {Object.keys(suggestions).map((category, index) => (
                    <div className={classes.smallBlockContainer} key={index}>
                        <span className={classes.smallBlockCategoryText}>{category}</span>
                        <div className={classes.wrapper}>
                        {Object.keys(suggestions[category]).map((smallBlock, index) => (
                            <button
                                className={classes.button}
                                key={index}
                                style={{ transition: 'background-color 0.3s ease', border: '2px solid #CEDFE0' }}
                                onClick={()=>{handleOnClick(suggestions[category][smallBlock] as string)}}
                                >
                                {smallBlock}    
                            </button>
                        ))}
                        </div>
                    </div>
                   ))}
            </div>
        ) : (
            <div className={classes.columnContainer}>
                {Object.keys(suggestions).map((category, index) => (
                    <div className={classes.largeBlockContainer} key={index}>
                        <span className={classes.largeBlockCategoryText}>{category}</span>
                        <div>
                            {Object.entries(suggestions[category].Options).map(([largeBlock, values], index) => (
                                <div key={index}
                                    onMouseEnter={() => {handleMouseEnter(largeBlock)}} 
                                    onMouseLeave={handleMouseLeave}>
                            
                                    <button className={classes.button} 
                                        style={{ transition: 'background-color 0.3s ease', border: '2px solid #CEDFE0' }}
                                        onClick={() => {handleOnClick(largeBlock)}}
                                    >
                                        {largeBlock}
                                    </button>
                                
                                    {isHovered === largeBlock && Array.isArray(values) && values.length > 0 && (
                                        <div>
                                            {values.map((arrayValue, arrayIndex) => (
                                                <div key={arrayIndex} className={classes.followUp}>
                                                    <ArrowRight20Regular style={{ paddingRight: '10px'}}/>      
                                                    <button 
                                                        className={classes.button}
                                                        style={{ transition: 'background-color 0.3s ease', border: '2px solid #CEDFE0' }}
                                                        onClick={() => {handleOnClick(arrayValue)}}
                                                    >
                                                        {arrayValue}
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                   
                                </div>
                            ))}
                        </div>
                    </div>              
                ))}                
            </div>
        )  
    )
}
