import {  createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FollowupQuestionState, initialState } from './FollowupQuestionState';


export const followupQuestionSlice = createSlice({
    name: 'followupquestions',
    initialState,
    reducers: {
        setFollowupquestions: (state: FollowupQuestionState, action: PayloadAction<{chatId: string,followupQuestion: string[]}>) => {
           
            const { chatId, followupQuestion } = action.payload;
            state.chatId = chatId;
            state.followupQuestions = followupQuestion;
        },
    },
});

export const { setFollowupquestions} = followupQuestionSlice.actions;

export default followupQuestionSlice.reducer;
