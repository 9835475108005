export interface FollowupQuestionState {
    followupQuestions: string[];
    chatId: string;
}

export const initialState: FollowupQuestionState = {
    followupQuestions: [],
    chatId: ''
};

