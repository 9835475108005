import React from 'react';
import { Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip, 
    Text,
} from '@fluentui/react-components';
import { ArrowDownload16Regular } from '@fluentui/react-icons';
import { makeStyles } from '@fluentui/react-components';
import * as XLSX from 'xlsx';
import PptxGenJS from "pptxgenjs";
import WordIcon from '../../../assets/icons8-microsoft-word-2019-48.png';
import ExcelIcon from '../../../assets/icons8-microsoft-excel-2019-48.png';
import PowerPointIcon from '../../../assets/icons8-microsoft-powerpoint-2019-48.png';
import ExportUtil from '../../utils/ExportUtils';

const useClasses = makeStyles({
    buttonContainer: {
        
    },
    menuButton: {

    },
    menuItemContainer: {
        display: 'flex', 
        alignItems: 'center',
    },
    menuImage:{
        width: '24px',
        height: '24px', 
        paddingRight:'10px'
    },
})

interface ExportButtonProps {
    id: string | undefined;
    chatName: string;
    disableExcel: boolean;
}    

const defaultText: PptxGenJS.TextProps = {
    text: "Default Text",
    options: {
        x: 0.0,
        y: 0.0,
        w:'100%',
        h: 1.5,
        align: 'left', 
        fontSize: 14, 
    }
};



const Export2Word = (id: string | undefined, filename?: string) => {

    if (!id) {
        console.error("Invalid element ID");
        return;
    }
    
    const targetElement = document.getElementById(id);

    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    const postHtml = "</body></html>";
    const html = preHtml + targetElement.innerHTML + postHtml;   
    const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    filename = filename ? filename + '.doc' : 'document.doc';
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();
    document.body.removeChild(downloadLink);   
}

const Export2Excel = (id: string | undefined, filename?: string) => {
   
    if (!id) {
        console.error("Invalid element ID");
        return;
    }
    
    const targetElement = document.getElementById(id);
  
    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const tables = targetElement.getElementsByTagName('table');
    const tableArray = Array.from(tables);
    const wb = XLSX.utils.book_new();

    tableArray.forEach((table, index) => {
        const ws = XLSX.utils.table_to_sheet(table);
        const sheetName = `Table_${index+1}`;
        XLSX.utils.book_append_sheet(wb, ws, sheetName)
    })

    // Specify file name
    filename = filename ? filename + '.xlsx' : 'excel_data.xlsx';

    // Save the workbook to a file
    XLSX.writeFile(wb, filename);
   
}


// const testObjs:  PptxGenJS.TextProps[] = [
//     {text: 'Sure, Thomas. Here is the list of top 5 Texas football players with their last names bolded:', options: {}},
//     {text: '', options: {breakLine: true}},
//     {text: '', options: {breakLine: true}},

//     {text: 'Level 1', options: {bullet: {type: "number"} , indentLevel: 1}},
//     {text: 'Some Text for Level 1', options: {}},
//     {text: ' Hey this should be belong in level 1', options :{}},
//     // {text: '', options: {breakLine: true}},

//     {text: 'Level 2', options: {bullet: {type: "number"} , indentLevel: 2}},
//     {text: 'Some Text for Level 2', options: {bold: true}},
//     {text: ' Hey this should be belong in level 2', options :{}},
//     // {text: '', options: {breakLine: true}},

//     {text: 'Level 1', options: {bullet: {type: "number"} , indentLevel: 1}},
//     {text: 'Some Text for Level 1', options: {}},
//     {text: ' Hey this should be belong in level 1', options :{}},

//     // {text: '', options: {breakLine: true}},

//     {text: 'Level 1', options: {bullet: true , indentLevel: 2}},
//     {text: 'Some Text for Level 2', options: {italic: true}},
//     {text: 'Hey this should be belong in level 2', options :{}},

//     // {text: '', options: {breakLine: true}},

// ];

const Export2PPTX = (id: string | undefined, filename: string) => {
    if (!id) {
        console.error("Invalid element ID");
        return;
    }
    
    const targetElement = document.getElementById(id);

    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const innerDiv = targetElement.querySelector('div');

    if (innerDiv) {
        const myObject = new ExportUtil({ data: innerDiv })
        myObject.createPPTX(filename)

    } else {
        const pptx = new PptxGenJS();
        const slide = pptx.addSlide();
        slide.addText([defaultText]);
        void pptx.writeFile({ fileName: "Error downloading" });
    }
}

export const ExportButton:React.FC<ExportButtonProps> = ({ id, chatName, disableExcel }) => {
    const classes = useClasses();
    const [hover, setHover] = React.useState("");

    const handleMouseEnter = (section: string) => {
        setHover(section);
    }
    
    const handleMouseLeave = () => {
        if (!id) {
            console.error("Invalid element ID");
            return;
        }
    }


    return (
        <div className={classes.buttonContainer}>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Tooltip content="Export" relationship="label">
                        <Button
                            icon={<ArrowDownload16Regular />}
                            style={{border:'none'}}
                            className={classes.menuButton}
                        >
                        </Button>
                    </Tooltip>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={() => { Export2Word(id, chatName) }}>
                            <div className={classes.menuItemContainer} id="Word" onMouseEnter={()=> {handleMouseEnter("Word")}} onMouseLeave={handleMouseLeave}>
                                <img src={WordIcon} alt="Word Icon" className={classes.menuImage} />
                                <Text style={{fontWeight: hover === "Word" ? 600 : 400}}>Word</Text>
                            </div>
                        </MenuItem>
                        {!disableExcel && (
                             <MenuItem onClick={() => { Export2Excel(id, chatName) }}>
                             <div className={classes.menuItemContainer} id="Excel" onMouseEnter={()=> {handleMouseEnter("Excel")}} onMouseLeave={handleMouseLeave}>
                                 <img src={ExcelIcon} alt="Excel Icon" className={classes.menuImage} />
                                 <Text style={{fontWeight: hover === "Excel" ? 600 : 400}}>Excel</Text>
                             </div>
                         </MenuItem>
                        )} 
                        <MenuItem onClick={() => { Export2PPTX(id, chatName) }}>
                            <div className={classes.menuItemContainer} id="PowerPoint" onMouseEnter={()=> {handleMouseEnter("PowerPoint")}} onMouseLeave={handleMouseLeave}>
                                <img src={PowerPointIcon} alt="PowerPoint Icon" className={classes.menuImage} />
                                <Text style={{fontWeight: hover === "PowerPoint" ? 600 : 400}}>Powerpoint</Text>
                            </div>                          
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>

        </div>
    )
}
